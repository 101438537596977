body {
    font-size: 12px;
}

@media (min-width: 800px) {
    body {
        padding: 30px 200px;
    }
}

.description {
    max-width: 600px;
}

.container {
    position: relative;
    margin: 8px 0 29px 0;
}


.horizontalScrollXAxisContainer {
    overflow: hidden;
}

.yAxisContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 20; /* above horizontalScrollContainer */
    pointer-events: none;
}


.yLabel {
    color: white;
    font-size: 12px;
    background: rgba(0, 0, 0, 0.63);
    padding: 0 4px 0 4px;
    margin: 0 8px 0 8px;
    border-radius: 2px;
}

.chartLabel {
    padding: 0px 12px 0px 12px;
}

.legend-container {
    padding: 0px 12px 0px 12px;
    margin-top: 8px;
    background-color: #fff;
    max-width: 350px;
    display: grid;
    grid-template-columns: min-content auto;
}

.legendTitle {
    padding: 3px 3px 3px 0;
}

.swatchesContainer {
    display: inline-grid;
    grid-template-columns: auto auto auto auto auto
}

.swatch {
    display: inline-block;
    margin: 2px;
    border-radius: 2px;
    font-size: 10px;
    text-align: center;
    padding: 3px 0 3px 0;
    font-weight: 600;
}

/**** Z-INDEX SIBLINGS ******/
.tooltip {
    z-index: 40; /* above y axis and everyting else */
    display: none;
    background-color: black;
    color: white;
    padding: 8px;
    border-radius: 4px;
    position: absolute;
    pointer-events: none; /* avoid flickering when mouse briefly moves over tooltip */

    .titleRow {
        display: grid;
        grid-template-columns: auto max-content;
        font-weight: bold;
    }
    .name {
        font-weight: bold;
        padding-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .subTotalRow {
        display: grid;
        grid-template-columns: auto max-content;
    }
}
.tooltipArrow {
    z-index: 40; /* above y axis and everyting else */
    display: none;
    position: absolute;
    pointer-events: none; /* avoid flickering when mouse briefly moves over tooltip */width: 0; 

    /* arrow down https://css-tricks.com/snippets/css/css-triangle/ */
    width: 0px;
    height: 0; 
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #000;
}

.stickyXAxisContainer {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 30; /* above plot area and y axis */
}

.horizontalScrollContainer {
    position: relative;
    top: 0px;
    overflow: auto;
    z-index: 10;
}

/**** END Z-INDEX SIBLINGS ******/
